<template>
    <div style="background-color: white; position: relative">
        <div style="position: sticky; top: 0; background-color: #fff; z-index: 1">
            <div
                v-if="writer"
                class="writer-tab-reviews__progress-bar-stats"
                style="position: sticky; top: 0px"
            >
                <writer-progress-bar
                    :title="'Content'"
                    :progress="+writer.statistics.rating_content_all"
                />
                <writer-progress-bar
                    :title="'Delivery'"
                    :progress="+writer.statistics.rating_delivery_all"
                />
                <writer-progress-bar
                    :title="'Communication'"
                    :progress="+writer.statistics.rating_communication_all"
                />
            </div>
            <div
                v-if="writer"
                class="writer-tab-reviews__sort"
            >
                <div class="writer-tab-reviews__rating-count-block">
                    <star />
                    <p style="margin-left: 5px">
                        {{ writer.statistics.rank_all }}
                    </p>
                    <p class="writer-tab-reviews__rating-count-block-quantity-review">
                        - {{ writer.statistics.rank_all_count }} {{ writer.statistics.rank_all_count > 1 ? 'reviews' : 'review' }}
                    </p>
                </div>
                <sorting
                    :sort-by-fields="sortByFields"
                    :search-object="searchObject"
                    :sort-order-fields="sortOrderFields"
                    class="writer__double-form-element"
                    @updateSorting="onUpdateSearch"
                />
            </div>
        </div>
        <div class="writer-tab-reviews">
            <div
                v-if="loading && !writerData.length"
                class="writer-tab-reviews__reviews-list--loading"
            >
                <PresetLoader />
            </div>
            <no-result
                v-else-if="!writerData.length && !loading"
                type="reviews"
            />
            <template v-else>
                <template v-for="(testimonial, index) in writerData">
                    <div
                        v-if="testimonial.data_new"
                        :key="index"
                        class="writer-tab-reviews__item"
                    >
                        <div class="writer-tab-reviews__item-header">
                            <div
                                class="writer-tab-reviews__item-header-wrapper"
                                style="display: flex; align-items: center"
                            >
                                <div class="writer-tab-reviews__item-header-country-flag-wraper">
                                    <img
                                        v-if="testimonial.client && testimonial.client.country"
                                        class="writer-tab-reviews__item-header-country-flag"
                                        :src="require('@/assets/img/flags/4x3/' + testimonial.client.country.toLowerCase() + '.svg')"
                                        alt=""
                                    >
                                </div>
                                <div style="margin-left: 15px">
                                    <span class="writer-tab-reviews__item-title">Country</span>
                                    <span
                                        v-if="testimonial.client"
                                        class="writer-tab-reviews__item-data"
                                    >
                                        {{ testimonial.client.country_nice_name }}
                                    </span>
                                </div>
                            </div>
                            <div class="writer-tab-reviews__item-header-wrapper">
                                <span class="writer-tab-reviews__item-title">Date:</span>
                                <span
                                    class="writer-tab-reviews__item-data"
                                >
                                    {{ testimonial.created_at | moment_from }} ago
                                    <span
                                        class="writer-tab-reviews__item-date-create--tooltip tooltip-zoom"
                                        :data-tooltip-attribute="testimonial.created_at | moment_full_text"
                                    >
                                        i
                                    </span>
                                </span>
                            </div>
                            <div class="writer-tab-reviews__item-header-wrapper">
                                <span class="writer-tab-reviews__item-title">Client ID:</span>
                                <span
                                    v-if="testimonial.client"
                                    class="writer-tab-reviews__item-data"
                                >
                                    {{ testimonial.client.id }}
                                </span>
                            </div>
                            <div class="writer-tab-reviews__item-header-wrapper">
                                <span class="writer-tab-reviews__item-title">Order</span>
                                <span
                                    v-if="testimonial.order"
                                    class="writer-tab-reviews__item-data"
                                >
                                    #{{ testimonial.order.orderid }}
                                </span>
                            </div>
                            <div class="writer-tab-reviews__item-header-wrapper">
                                <span class="writer-tab-reviews__item-title">Review</span>
                                <span class="writer-tab-reviews__item-data">
                                    <star-rating
                                        :star-size="20"
                                        :increment="0.1"
                                        :rating="testimonial.rank"
                                        :active-color="themecolor"
                                        :read-only="true"
                                    />
                                </span>
                            </div>
                        </div>
                        <div class="writer-tab-reviews__item-body">
                            <div class="writer-tab-reviews__item-body-details">
                                <span
                                    v-if="testimonial.data_new.details"
                                    class="writer-tab-reviews__item-body-details-content"
                                >
                                    "{{ testimonial.data_new.details }}"
                                </span>
                                <!--                            <div-->
                                <!--                                class="writer-tab-reviews__item-body-details-chevron"-->
                                <!--                                @click.stop="slideCard(index)"-->
                                <!--                            >-->
                                <!--                                <span-->
                                <!--                                    v-if="isCardOpen === index"-->
                                <!--                                    class="button-action-background button-action-background-active"-->
                                <!--                                >-->
                                <!--                                    <chevron-up-icon />-->
                                <!--                                </span>-->
                                <!--                                <span-->
                                <!--                                    v-else-->
                                <!--                                    class="button-action-background"-->
                                <!--                                >-->
                                <!--                                    <chevron-down-icon />-->
                                <!--                                </span>-->
                                <!--                            </div>-->
                                <!--                            v-if="isCardOpen === index"-->
                            </div>
                            <div>
                                <WriterReviewAdditionalBlock
                                    :title="'Content'"
                                    :details="testimonial.data_new.content_details"
                                    :counter="testimonial.data_new.content"
                                />
                                <WriterReviewAdditionalBlock
                                    :title="'Delivery'"
                                    :details="testimonial.data_new.delivery_details"
                                    :counter="testimonial.data_new.delivery"
                                />
                                <WriterReviewAdditionalBlock
                                    :title="'Communication'"
                                    :details="testimonial.data_new.communication_details"
                                    :counter="testimonial.data_new.communication"
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        v-else
                        :key="index"
                        class="writer-tab-reviews__item"
                    >
                        <div class="writer-tab-reviews__item-header">
                            <div
                                class="writer-tab-reviews__item-header-wrapper"
                                style="display: flex; align-items: center"
                            >
                                <div class="writer-tab-reviews__item-header-country-flag-wraper">
                                    <img
                                        v-if="testimonial.client && testimonial.client.country"
                                        class="writer-tab-reviews__item-header-country-flag"
                                        :src="require('@/assets/img/flags/4x3/' + testimonial.client.country.toLowerCase() + '.svg')"
                                        alt=""
                                    >
                                </div>
                                <div style="margin-left: 15px">
                                    <span class="writer-tab-reviews__item-title">Country</span>
                                    <span
                                        v-if="testimonial.client"
                                        class="writer-tab-reviews__item-data"
                                    >
                                        {{ testimonial.client.country_nice_name }}
                                    </span>
                                </div>
                            </div>
                            <div class="writer-tab-reviews__item-header-wrapper">
                                <span class="writer-tab-reviews__item-title">Date:</span>
                                <span
                                    class="writer-tab-reviews__item-data"
                                >
                                    {{ testimonial.created_at | moment_from }} ago
                                    <span
                                        class="writer-tab-reviews__item-date-create--tooltip tooltip-zoom"
                                        :data-tooltip-attribute="testimonial.created_at | moment_full_text"
                                    >
                                        i
                                    </span>
                                </span>
                            </div>
                            <div class="writer-tab-reviews__item-header-wrapper">
                                <span class="writer-tab-reviews__item-title">Client ID:</span>
                                <span
                                    v-if="testimonial.client"
                                    class="writer-tab-reviews__item-data"
                                >
                                    {{ testimonial.client.id }}
                                </span>
                            </div>
                            <div class="writer-tab-reviews__item-header-wrapper">
                                <span class="writer-tab-reviews__item-title">Order</span>
                                <span
                                    v-if="testimonial.order"
                                    class="writer-tab-reviews__item-data"
                                >
                                    #{{ testimonial.order.orderid }}
                                </span>
                            </div>
                            <div class="writer-tab-reviews__item-header-wrapper">
                                <span class="writer-tab-reviews__item-title">Review</span>
                                <span class="writer-tab-reviews__item-data">
                                    <star-rating
                                        :star-size="20"
                                        :increment="0.1"
                                        :rating="testimonial.rank"
                                        :active-color="themecolor"
                                        :read-only="true"
                                    />
                                </span>
                            </div>
                        </div>
                        <div class="writer-tab-reviews__item-body">
                            <div class="writer-tab-reviews__item-body-details">
                                <span
                                    v-if="testimonial.data_v1.details"
                                    class="writer-tab-reviews__item-body-details-content"
                                >
                                    "{{ testimonial.data_v1.details }}"
                                </span>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="writer-tab-reviews__loadmore">
                    <custom-button
                        v-if="haveNextPage"
                        default
                        class="button btn-base_colored"
                        :loading="loading"
                        @on-btn-click="loadMore"
                    >
                        Load more
                    </custom-button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>

import Api from '@/helpers/api/index.js'
import filtersMixin from '@/mixins/filters-mixin'

import StarRating from 'vue-star-rating'
import NoResult from '@/components/common/NoResult'
import WriterProgressBar from '@/components/writers/WriterProgressBar.vue';
import PresetLoader from '@/components/common/PresetLoader';
import Star from '@/components/icons/Star.vue';
import WriterReviewAdditionalBlock from '@/components/writers/WriterReviewAdditionalBlock.vue';

import { eventBus } from '@/helpers/event-bus/'
import Sorting from '@/components/common/Sorting.vue'

const Env = require('@/helpers/environment/index.js');

const EnvSettings = new Env(process.env.VUE_APP_DOMAIN);

export default {
    name: 'WriterTabReviews',
    components: {
        StarRating,
        NoResult,
        WriterProgressBar,
        WriterReviewAdditionalBlock,
        PresetLoader,
        Star,
        Sorting

    },
    mixins: [
        filtersMixin
    ],
    props: {
        writer: {
            type: Object
        },
        writerId: {
            type: Number
        }
    },
    data() {
        return {
            themecolor: EnvSettings.styleSetting.color,
            // isCardOpen: false,
            writerData: [],
            loading: true,
            haveNextPage: false,
            searchObject: {
                page: 1,
                per_page: 10,
                sort_by: 'created_at',
                sort_order: 'DESC'
            }
        }
    },
    computed: {
        writerIdForList() {
            return this.writerId || this.$route.params.id
        },
        sortByFields() {
            return [
                {
                    text: 'Created',
                    value: 'created_at'
                }
            ]
        },
        sortOrderFields() {
            return [
                {
                    text: 'ASC',
                    value: 'ASC'
                },
                {
                    text: 'DESC',
                    value: 'DESC'
                }
            ]
        }
    },
    methods: {
        async getTestimonials() {
            try {
                this.loading = true
                const { data } = await Api.get('/api/testimonial/list', { id: this.writerIdForList, ...this.searchObject })
                this.writerData = [...this.writerData, ...data.data]
                this.haveNextPage = Boolean(data.next_page_url)
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.loading = false
            }
        },
        async onUpdateSearch(payload) {
            this.writerData = []
            this.searchObject = {
                ...this.searchObject,
                page: 1,
                ...payload
            }
            await this.getTestimonials()
        },
        async loadMore() {
            this.searchObject.page += 1
            await this.getTestimonials()
        }
    }
}

</script>

<style lang="scss">
    .writer-tab-reviews{
        padding: 20px;
        background-color: $white;
        &__progress-bar-stats{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            background-color: $white;
            border-bottom: 2px solid #EAEAEA;
            padding: 10px 0;
        }
        &__sort{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            box-shadow: 0 10px 30px 0 rgb(0 0 0 / 8%);
        }
        &__rating-count-block{
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            display: flex;
            align-items: center;
            svg {
                width: 20px;
                height: 20px;
                fill: $main-color;
            }
        }
        &__rating-count-block-quantity-review{
            margin-left: 5px;
            @media all and (max-width: 576px) {
                display: none;
            }
        }
        &__reviews-list--loading{
            height: 700px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                width: 300px;
                height: 300px;
                rect {
                    width: 10px;
                }
            }
        }
        &__item{
            padding: 20px;
            box-shadow: 0px 0px 4px 1px rgba(16, 24, 40, 0.05);
            border-radius: 4px;
            margin-bottom: 15px;
        }
        &__item-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1.5px solid #EAEAEA;
            padding-bottom: 10px;
            @media all and (max-width: 992px) {
                flex-wrap: wrap;
                gap: 15px;
            }
        }
        &__item-header-wrapper{
            @media all and (max-width: 400px) {
                width: 100%;
            }
        }
        &__item-header-country-flag-wraper{
            border-radius: 50%;
            overflow: hidden;
            width: 30px;
            display: flex;
            height: 30px;
        }
        &__item-header-country-flag{
            max-width: 100%;
            height: auto;
            object-fit: cover;
        }
        &__item-title{
            display: block;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #111720;
        }
        &__item-data{
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #111720;
            white-space: nowrap;
            .vue-star-rating-rating-text{
                color: $main-color;
                font-weight: 700;
            }
        }
        &__item-date-create--tooltip {
            border: 1px solid #1f2939;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            transition: all 0.1s ease-in;
            cursor: pointer;
            margin-left: 3px;
        }
        &__item-body{
            padding-top: 10px;
        }
        &__item-body-details{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &__item-body-details-content{
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #111720;
            overflow: hidden;
        }
        &__item-body-details-chevron{
            padding: 0px 25px;
            margin-left: 10px;
            border-left: 1px dashed #EAEAEA;
            cursor: pointer;
            align-self: stretch;
            display: flex;
            align-items: center;
            @media (max-width: 768px) {
                padding: 0px 20px 0 10px;
            }
        }
        &__item-body-other-details{
            font-size: 14px;
            line-height: 20px;
            padding: 10px 0 10px 10px;
            margin: 15px 0;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &__item-body-other-details--success{
            background: #F2FBF2;
        }
        &__item-body-other-details--unsuccessful{
            background: #FCF2F2;
        }
        &__item-body-other-details-title {
            font-weight: 600;
        }
        &__item-body-other-details-content{
            margin-top: 10px;
        }
        &__item-body-other-details-count{
            font-weight: 700;
            white-space: nowrap;
            margin: 0 10px;
            padding: 0 10px;
            align-self: stretch;
            display: flex;
            align-items: center;
            @media (max-width: 768px) {
                margin: 0 14px;
                padding: 0 14px;
            }
        }
        &__item-body-other-details-count-all{
            font-size: 14px !important;
            color: #111720 !important;
            margin: 3px 0 0 5px;
            @media (max-width: 768px) {
                display: none;
            }
        }
        &__item-body-other-details-count--success{
            font-size: 18px;
            color: #1FB119;
            border-left: 1px dashed #D1F2D0;
        }
        &__item-body-other-details-count--unsuccessful{
            font-size: 18px;
            color: #B71A1A;
            border-left: 1px dashed #F5D0D0;
        }
        &__loadmore{
            display: flex;
            justify-content: center;
        }
    }
    .writer__double-form-element{
        display: flex;
        align-items: center;
        .double-form-element {
            border: 1px solid #D3D9E2;
        }
        .form-select__legend {
            display: none ;
        }
        .form-select__input {
            height: 48px;
            background-color: transparent;
            color: #111720;
            width: 150px;
            @media screen and (max-width: 992px){
                width: 105px;
            }
        }
        .form-select__list {
            overflow: hidden;
            top: 50px;
            z-index: 13;
            background-color: transparent;
        }
        .form-select__list-item {
            color: black;
            padding: 10px 15px;
            border-bottom: 1px solid #e7efef;
        }
    }
</style>
